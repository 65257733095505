import "../../common/polyfills";

declare var CampaignCentral: any;



interface ISurveyFormProps {
    SurveyId: string;
    ContentId: string;

    // todo implement these
    FormHeader?: string;
    FormButtonText?: string;
    HideRespondentDetails?: boolean;
    RequiredFields?: RespondentField[]
}

type RespondentField = "LastName" | "FirstName" | "Address" | "Postcode" | "Email" | "Mobile";

function MountSurveyForm(props: ISurveyFormProps, el: Element) {
    const { SurveyId, ContentId, FormHeader, HideRespondentDetails, RequiredFields, FormButtonText } = props;
    let vm: any = null;

    fetch(`/Umbraco/API/SurveyForm/Model?surveyFormId=${SurveyId}&contentId=${ContentId}`,
        { method: 'GET' })
        .then(res => res.json())
        .then((model) => {
            vm = new CampaignCentral.Survey.ManualSurveyEntryViewModel({
                respondentTitle: FormHeader,
                respondentSubmitButtonText: FormButtonText,
                respondentDetailsRequired: !HideRespondentDetails,
                survey: model,
                theme: new CampaignCentral.SurveyTheme.Umb_AlpPrincipleSurveyTheme(),
                showRequiredFieldsAsModal: true,
                showSurveyErrorsAsModal: true,
                requiredFields: RequiredFields,
                dateFormat: "DD/MM/YYYY",
                size: "small",
                onSubmit: (surveyResponse: any) => {

                    // surveyResponse["Tags"] = (this.props.values["VotedOption"] ? `Voted for: ${this.props.values["VotedOption"]}` : "");

                    var surveyResponseStr = (surveyResponse ? JSON.stringify(surveyResponse) : "");
                    //submit to umbraco first and save to umbraco db
                    fetch("/Umbraco/Api/SurveyForm/Submit",
                        {
                            body: JSON.stringify(
                                {
                                    SurveyFormId: SurveyId,
                                    ContentId: ContentId,
                                    SurveyResponseJson: surveyResponseStr,
                                    Email: surveyResponse["Email"],
                                    FirstName: surveyResponse["FirstName"],
                                    LastName: surveyResponse["LastName"],
                                    Address: surveyResponse["Address"],
                                    Postcode: surveyResponse["Postcode"],
                                    Mobile: surveyResponse["Mobile"]
                                }),
                            headers: { "Content-Type": "application/json" },
                            method: "POST"
                        }).then(function (res) {
                            console.log(res);
                            submitSuccess();
                        });
                }
            });
            vm.mount(el);
        }).catch((err: any) => {
            console.log(err);
            alert('Error loading survey, please try again later');
        });



    function submitSuccess() {
        vm.unmount();
        vm = null;

        const msg = document.createElement("h2");
        msg.innerText = "Submission received. Thank you for completing the form.";
        el.appendChild(msg);

    }




}

(window as any).MountSurveyForm = MountSurveyForm;

